<template>
  <div class="table">
    <a-table
      class="table-benchmarking"
      :columns="columns"
      :data-source="dataSource"
      :scroll="{ x: 'calc(700px + 50%)', y: 'calc(100vh - 244px)' }"
      :pagination="false"
      bordered
    >
      <template #headerCell="{ column }">
        <template v-if="column.key === 'groupCarbon'">
          <span class="table-head">{{ column.title }}</span>
        </template>

        <template v-if="column.key === 'groupFactors'">
          <span class="table-head">{{ column.title }}</span>
        </template>

        <template v-if="column.key === 'dataSource'">
          <span class="table-head">{{ column.title }}</span>
        </template>
      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'name'">
          <a-typography-text class="table-key-cell">
            {{ record.vessel.name }}
          </a-typography-text>
        </template>

        <template v-if="column.dataIndex === 'type'">
          <a-typography-text type="secondary">
            {{ record.vessel.type }}
          </a-typography-text>
        </template>

        <template v-if="column.dataIndex === 'grade'">
          <Shape :background="handleGrade(record.grade)" :text="record.grade" />
        </template>

        <template v-if="column.dataIndex === 'cii'">
          {{ kFormatter.format(record.cii) }}
        </template>

        <template v-if="column.dataIndex === 'cii_yty'">
          <PercentDirection :value="parseFloat(record.cii_yty)" />
        </template>

        <template v-if="column.dataIndex === 'rpm_power_profile'">
          {{ kFormatter.format(record.rpm_power_profile) }}
        </template>

        <template v-if="column.dataIndex === 'rpm_yty'">
          <PercentDirection :value="parseFloat(record.rpm_yty)" />
        </template>

        <template v-if="column.dataIndex === 'jit_arrival'">
          {{ kFormatter.format(record.jit_arrival) }}
        </template>

        <template v-if="column.dataIndex === 'jit_yty'">
          <PercentDirection :value="parseFloat(record.jit_yty)" />
        </template>

        <template v-if="column.dataIndex === 'hullProp'">
          <a-typography-text type="secondary">N/A</a-typography-text>
        </template>

        <template v-if="column.dataIndex === 'hullPropYty'">
          <a-typography-text type="secondary">N/A</a-typography-text>
        </template>

        <template v-if="column.dataIndex === 'source_ais'">
          <div class="table-check">
            <CheckCircleFilled v-if="record.source_ais" />
          </div>
        </template>

        <template v-if="column.dataIndex === 'source_noon'">
          <div class="table-check">
            <CheckCircleFilled v-if="record.source_noon" />
          </div>
        </template>

        <template v-if="column.dataIndex === 'source_sensor'">
          <div class="table-check">
            <CheckCircleFilled v-if="record.source_sensor" />
          </div>
        </template>

        <!-- <template v-if="column.key === 'action'">
          <div class="table-action">
            <router-link to="/">
              <RightOutlined />
            </router-link>
          </div>
        </template> -->
      </template>
    </a-table>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

import { CheckCircleFilled } from "@ant-design/icons-vue";
import { kFormatter } from "@/helpers";


export default defineComponent({
  name: "BenchmarkingFleet",

  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
  },

  setup() {
    const handleGrade = (grade) => {
      if (grade === "A") {
        return "#00bc4b";
      } else if (grade === "B") {
        return "#89b900";
      } else if (grade === "C") {
        return "#DEDE3B";
      } else if (grade === "D") {
        return "#ffc000";
      } else if (grade === "E") {
        return "#ee3440";
      }
    };

    const { t } = useI18n();

    const columns = ref([
      {
        title: t('benchmarking-page.vessel name'),
        dataIndex: "name",
        key: "vessel.name",
        width: 200,
        sorter: (a, b) => a.vessel.name.length - b.vessel.name.length,
      },
      {
        title: t('benchmarking-page.type'),
        dataIndex: "type",
        key: "vessel.type",
        width: 200,
        sorter: (a, b) => a.vessel.type.length - b.vessel.type.length,
      },
      {
        title: t('benchmarking-page.carbon intensity index'),
        key: "groupCarbon",
        children: [
          {
            title: t('benchmarking-page.CII'),
            dataIndex: "cii",
            key: "cii",
            width: 100,
            sorter: (a, b) => a.cii - b.cii,
          },
          {
            title: t('benchmarking-page.grade'),
            dataIndex: "grade",
            key: "grade",
            width: 100,
            sorter: (a, b) => a.grade - b.grade,
          },
          {
            title: t('benchmarking-page.YTY'),
            dataIndex: "cii_yty",
            key: "cii_yty",
            width: 100,
            sorter: (a, b) => a.cii_yty - b.cii_yty,
          },
        ],
      },
      {
        title: t('benchmarking-page.SEEMP optimization factors'),
        key: "groupFactors",
        children: [
          {
            title: t('benchmarking-page.RPM/power'),
            dataIndex: "rpm_power_profile",
            key: "rpm_power_profile",
            width: 130,
            sorter: (a, b) => a.rpm_power_profile - b.rpm_power_profile,
          },
          {
            title: t('benchmarking-page.YTY'),
            dataIndex: "rpm_yty",
            key: "rpm_yty",
            width: 100,
            sorter: (a, b) => a.rpm_yty - b.rpm_yty,
          },
          {
            title: t('benchmarking-page.JIT arrival'),
            dataIndex: "jit_arrival",
            key: "jit_arrival",
            width: 130,
            sorter: (a, b) => a.jit_arrival - b.jit_arrival,
          },
          {
            title: t('benchmarking-page.YTY'),
            dataIndex: "jit_yty",
            key: "jit_yty",
            width: 100,
            sorter: (a, b) => a.jit_yty - b.jit_yty,
          },
          {
            title: t('benchmarking-page.hullprop'),
            dataIndex: "hullProp",
            key: "hullProp",
            width: 130,
            sorter: (a, b) => a.hullProp - b.hullProp,
          },
          {
            title: t('benchmarking-page.YTY'),
            dataIndex: "hullPropYty",
            key: "hullPropYty",
            width: 100,
            sorter: (a, b) => a.hullPropYty - b.hullPropYty,
          },
        ],
      },
      {
        title: t('benchmarking-page.data source'),
        key: "dataSource",
        children: [
          {
            title: t('benchmarking-page.track'),
            dataIndex: "source_ais",
            key: "source_ais",
            width: 100,
            sorter: (a, b) => a.source_ais - b.source_ais,
          },
          {
            title: t('benchmarking-page.noon'),
            dataIndex: "source_noon",
            key: "source_noon",
            width: 100,
            sorter: (a, b) => a.source_noon - b.source_noon,
          },
          {
            title: t('benchmarking-page.sensor'),
            dataIndex: "source_sensor",
            key: "source_sensor",
            width: 100,
            sorter: (a, b) => a.source_sensor - b.source_sensor,
          },
        ],
      },
      // {
      //   key: "action",
      // },
    ]);

    return {
      columns,
      handleGrade,
      kFormatter,
    };
  },

  components: {
    CheckCircleFilled,
  },
});
</script>

<style lang="less" scoped>
.table {
  &-head {
    display: block;
    text-align: left;
    text-transform: uppercase;
  }
  &-key-cell{
    color: @color-primary;
    font-weight: bold;
    font-size: 15px;
  }

  &-check {
    color: @color-secondary;
    display: flex;
    justify-content: center;
    opacity: 0.5;
  }

  &-action {
    display: flex;
    justify-content: flex-end;

    a {
      &:not(:hover) {
        color: @color-secondary;
      }
    }
  }
}
</style>
