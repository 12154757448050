<template>
  <div class="table">
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :scroll="{ x: 'calc(700px + 50%)', y: 'calc(100vh - 244px)' }"
      :pagination="false"
      bordered
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'name'">
          <a-typography-text class="table-key-cell">
            {{ record.name }}
          </a-typography-text>
        </template>

        <template v-if="column.dataIndex === 'locode'">
          <a-typography-text type="secondary">
            {{ record.locode != "nan" ? record.locode : "-" }}
          </a-typography-text>
        </template>

        <template v-if="column.dataIndex === 'arrivals_count'">
          {{ kFormatter.format(record.arrivals_count) }}
        </template>

        <template v-if="column.dataIndex === 'avg_time'"> - </template>

        <template v-if="column.dataIndex === 'avg_demurrage_hours'">
          {{ kFormatter.format(record.avg_demurrage_hours) }}
        </template>

        <template v-if="column.dataIndex === 'co2_impact'">
          {{ kFormatter.format(record.co2_impact) }}
        </template>

        <template v-if="column.dataIndex === 'yty'">
          <PercentDirection :value="parseFloat(record.yty)" :showZero="true" />
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

import { kFormatter } from "@/helpers";

export default defineComponent({
  name: "BenchmarkingPorts",

  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
  },

  setup() {
    const { t } = useI18n();

    const columns = ref([
      {
        title: t('benchmarking-page.port'),
        dataIndex: "name",
        key: "name",
        width: 200,
        sorter: (a, b) => a.name.length - b.name.length,
      },
      {
        title: t('benchmarking-page.locode'),
        dataIndex: "locode",
        key: "type",
        width: 200,
        sorter: (a, b) => a.locode.length - b.locode.length,
      },
      {
        title: t('benchmarking-page.no of arrivals'),
        dataIndex: "arrivals_count",
        key: "arrivals_count",
        width: 200,
        sorter: (a, b) => a.arrivals_count - b.arrivals_count,
      },
      {
        title: t('benchmarking-page.avg time in port, h'),
        dataIndex: "avg_time",
        key: "avg_time",
        width: 200,
      },
      {
        title: t('benchmarking-page.avg demurrage at anchor, h'),
        dataIndex: "avg_demurrage_hours",
        key: "avg_demurrage_hours",
        width: 200,
        sorter: (a, b) => a.avg_demurrage_hours - b.avg_demurrage_hours,
      },
      {
        title: t('benchmarking-page.demurrage anticipated CO2 impact, ton'),
        dataIndex: "co2_impact",
        key: "co2_impact",
        width: 300,
        sorter: (a, b) => a.co2_impact - b.co2_impact,
      },
      {
        title: t('benchmarking-page.YTY'),
        dataIndex: "yty",
        key: "yty",
        width: 100,
        sorter: (a, b) => a.yty - b.yty,
      },
    ]);

    return {
      columns,
      kFormatter,
    };
  },
});
</script>

<style lang="less" scoped>
.table-key-cell {
    color: @color-primary;
    font-weight: bold;
    font-size: 15px;
  }
</style>