import { useStore } from 'vuex';

import axios from 'axios';

export default () => {
  const store = useStore();

  const fetchFleetsBenchmark = () => {
    return axios.get('/api/fleet_benchmark').then(({ data }) => {
      store.commit('fleetBenchmark/setFleets', data);
    });
  };

  const fetchPortsBenchmark = () => {
    return axios.get('/api/ports_benchmark').then(({ data }) => {
      store.commit('fleetBenchmark/setPorts', data);
    });
  };

  const fleets = () => {
    const { state } = store;

    return state.fleetBenchmark.fleets;
  };

  const ports = () => {
    const { state } = store;

    return state.fleetBenchmark.ports;
  };

  return {
    fetchFleetsBenchmark,
    fetchPortsBenchmark,
    fleets,
    ports,
  };
};
