<template>
  <div class="layout">
    <div class="title">
      <div class="title-left">
        <a-typography-title :level="3">{{ $t('benchmarking-page.fleet benchmarking') }}</a-typography-title>

        <div class="title-filter">
          <a-radio-group
            v-model:value="selectedType.type"
            @change="changeTab"
            button-style="solid"
          >
            <a-radio-button value="fleet">{{ $t('benchmarking-page.fleet') }}</a-radio-button>
            <a-radio-button value="ports">{{ $t('benchmarking-page.ports') }}</a-radio-button>
          </a-radio-group>

          <div class="title-filter-inner">
            <div v-if="selectedType.type === 'fleet'">
              <a-select
                :placeholder="$t('benchmarking-page.all ships')"
                style="width: 174px"
                v-model:value="filterParams.selectedShip"
                @change="handleChangeFilterShip"
              >
                <a-select-option value="ALL"> {{$t('benchmarking-page.all ships')}} </a-select-option>

                <a-select-option
                  v-for="(item, index) in fleets"
                  :value="item.id"
                  :key="index"
                >
                  {{ item.vessel.name }}
                </a-select-option>
              </a-select>
            </div>

            <a-select placeholder="12 months" style="width: 174px" value="1">
              <a-select-option value="1">12 months</a-select-option>
            </a-select>
          </div>
        </div>
      </div>

      <div class="title-right">
        <div class="search" v-if="selectedType.type === 'fleet'">
          <a-input
            :placeholder="$t('benchmarking-page.search')"
            v-model:value="stateSearch.result"
            @change="handleChangeSearch"
          />

          <SearchOutlined class="search-icon" />
        </div>

        <!-- <a-dropdown v-if="selectedType.type === 'FLEET'">
          <a class="ant-dropdown-link" @click.prevent>
            <DownOutlined />
            Columns
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a href="">Column Name</a>
              </a-menu-item>
              <a-menu-item>
                <a href="">Column Name 2</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown> -->

        <!-- <a href="" class="export-report">Export report</a> -->
      </div>
    </div>

    <template v-if="!isLoading">
      <BenchmarkingFleet
        v-if="selectedType.type === 'fleet'"
        :dataSource="fleetsDataSource.list"
      />
      <BenchmarkingPorts
        v-if="selectedType.type === 'ports'"
        :dataSource="portsDataSource.list"
      />
    </template>

    <a-skeleton active class="loading" v-else />
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  onMounted,
  watchEffect,
  reactive,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import useFleetBenchmark from "@/composables/useFleetBenchmark";
import { useLoading } from "@/composables/useLoading";
import { SearchOutlined } from "@ant-design/icons-vue";
import { BenchmarkingFleet, BenchmarkingPorts } from "./components";

import { icons } from "@/assets";

export default defineComponent({
  name: "Benchmarking",

  setup() {
    const route = useRoute();
    const router = useRouter();
    const tab = route.params.tab || "fleet";
    const stateSearch = reactive({ result: "" });
    const selectedType = reactive({ type: tab });
    const fleetsDataSource = reactive({ list: [] });
    const portsDataSource = reactive({ list: [] });
    const filterParams = reactive({ selectedShip: "ALL" });

    const loading = useLoading();
    const fleetBenchmark = useFleetBenchmark();

    const fleets = computed(() => fleetBenchmark.fleets());
    const ports = computed(() => fleetBenchmark.ports());

    onMounted(() => {
      loading.start();

      Promise.all([
        fleetBenchmark.fetchFleetsBenchmark(),
        fleetBenchmark.fetchPortsBenchmark(),
      ]).then(() => {
        loading.finish();
      });
    });

    watchEffect(() => {
      selectedType.type = tab;

      if (!fleetsDataSource.list.length) {
        fleetsDataSource.list = fleets.value;
      }

      if (!portsDataSource.list.length) {
        portsDataSource.list = ports.value;
      }
    });

    const handleChangeSearch = (e) => {
      const value = e.target.value;

      stateSearch.result = value;

      if (value === "") {
        return (fleetsDataSource.list = fleets.value);
      } else {
        fleetsDataSource.list = fleets.value.filter((item) => {
          if (item.vessel.name.toLowerCase().includes(value.toLowerCase())) {
            return item;
          }
        });
      }
    };

    const handleChangeFilterShip = (id) => {
      if (id === "ALL") {
        return (fleetsDataSource.list = fleets.value);
      } else {
        fleetsDataSource.list = fleets.value.filter((item) => item.id === id);
      }
    };

    const changeTab = () => {
      router.push({ name: "Benchmarking", params: { tab: selectedType.type } });
    };

    return {
      icons,
      fleetsDataSource,
      portsDataSource,
      handleChangeSearch,
      stateSearch,
      filterParams,
      fleets,
      ports,
      selectedType,
      handleChangeFilterShip,
      isLoading: loading.active,
      changeTab,
    };
  },

  components: {
    BenchmarkingFleet,
    BenchmarkingPorts,
    SearchOutlined,
  },
});
</script>

<style lang="less" scoped>
.loading {
  width: 100vw;
  height: 100vh;
  padding: 10px 10px;
}
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.75rem 2.8rem 1.75rem 2.8rem;

  h3.ant-typography {
    text-transform: uppercase;
  }

  &-left {
    display: flex;
    align-items: center;
  }

  &-filter {
    display: flex;
    align-items: center;
    margin-left: 6.4rem;
    gap: 4.8rem;

    .ant-radio-group:deep {
      .ant-radio-button-wrapper {
        width: 50%;
        text-align: center;
        &:not(.ant-radio-button-wrapper-checked){
          border-color: @input-border-color;
        }
      }
    }

    &-inner {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  &-right {
    display: flex;
    align-items: center;
    gap: 4.8rem;

    .search {
      width: 24rem;
      position: relative;

      .ant-input {
        border-radius: 0px;
        padding-left: 1.6rem;
        padding-right: 3.6rem;
      }

      &-icon {
        color: @color-secondary;
        font-size: 1.8rem;
        position: absolute;
        top: 0.8rem;
        right: 1rem;
      }
    }

    .ant-dropdown-link {
      &:not(:hover) {
        color: @color-secondary;
      }
    }

    .export-report {
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}
</style>
